import React, { Component, Fragment } from 'react';
import { Alert, Form, Input, Button, Icon, Col, Divider, Spin } from 'antd';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import estimatePasswordStrength from 'zxcvbn';
import Link from 'components/common/Link';
import Spacing from 'components/common/Spacing';
import InputError from 'components/common/InputError';
import PasswordStrengthIndicator from 'components/common/PasswordStrengthIndicator';
import withCreatePccAuthUrl from 'graphql/hocs/pcc/withCreatePccAuthUrl';
import PccSignInButton from '../PccSignInButton';

const FormItem = Form.Item;

const Wrapper = styled.div`
  .logo-wrapper {
    text-align: center;
    img {
      height: 75px;
    }
  }
  .title {
    color: white;
    font-weight: lighter;
  }

  .ant-form-item-with-help {
    margin-bottom: 10px;
  }
  .ant-input {
    border-radius: 8px;
    max-width: 320px;
  }
  .ant-input-affix-wrapper {
    font-size: 18px;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 37px;
  }

  .ant-btn-primary-disabled,
  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary-disabled:hover,
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary-disabled:focus,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary-disabled:active,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary-disabled.active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    border-color: #1338a6 !important;
  }

  .ant-alert {
    padding: 15px;
  }

  .ant-alert-icon {
    position: relative;
    right: 15px;
    left: -10px;
    top: 0;
  }

  .styled-form {
    .ant-form-explain {
      text-align: left;
    }
  }

  .styled-input {
    border-radius: 8px;
    max-width: 320px;
    .anticon {
      color: #1338a6;
    }
  }
  .text-white {
    color: white;
  }
  .signup-link {
    color: white;
    padding: 0 5px;

    span {
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: #4260ba !important;
  max-width: 160px;
  border-radius: 8px !important;
`;

const DefaultButton = styled(StyledButton)`
  background-color: #dddddd !important;
`;

const { d2cEnabled, pccEnabled } = getConfig().publicRuntimeConfig;

@withCreatePccAuthUrl('login')
class LoginForm extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func,
      getFieldValue: PropTypes.func,
      validateFields: PropTypes.func,
      setFieldsValue: PropTypes.func,
    }).isRequired,
    isLoadingLogin: PropTypes.bool.isRequired,
    onCreatePccAuthUrl: PropTypes.func,
    onSetShouldShowResetPwForm: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pccAuthCode: PropTypes.string,
    pccAuthUrl: PropTypes.string,
    pccErrorMessage: PropTypes.string,
    pccIsLoadingLogin: PropTypes.bool,
    shouldShowResetPwForm: PropTypes.bool,
    successMessage: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    successMessage: '',
    pccErrorMessage: '',
    pccIsLoadingLogin: false,
    pccAuthCode: '',
    onCreatePccAuthUrl: null,
    pccAuthUrl: '',
    shouldShowResetPwForm: false,
  };

  state = { passwordStrength: -1 };

  componentDidUpdate(prevProps) {
    const { shouldShowResetPwForm, pccAuthUrl, form } = this.props;

    if (pccAuthUrl) {
      window.location.assign(pccAuthUrl);
    }

    if (shouldShowResetPwForm && !prevProps.shouldShowResetPwForm) {
      form.setFieldsValue({ newPassword: '', confirmPassword: '' });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      onSubmit,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        const { confirmPassword, ...otherValues } = values;
        onSubmit(otherValues);
      }
    });
  };

  handlePccSso = () => {
    const { onCreatePccAuthUrl } = this.props;
    onCreatePccAuthUrl();
  };

  displaySuccessMessage() {
    const { successMessage } = this.props;
    if (successMessage) {
      return (
        <Fragment>
          <Alert message={successMessage} type="success" showIcon />
          <Spacing size={20} />
        </Fragment>
      );
    }
    return null;
  }

  displayErrorMessage() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      return (
        <Fragment>
          <Alert message={errorMessage} type="error" showIcon />
          <Spacing size={30} />
        </Fragment>
      );
    }
    return null;
  }

  displayPccErrorMessage() {
    const { pccErrorMessage } = this.props;
    if (pccErrorMessage) {
      return (
        <Fragment>
          <Alert message={pccErrorMessage} type="error" showIcon />
          <Spacing size={30} />
        </Fragment>
      );
    }
    return null;
  }

  renderFormContent() {
    const { form, isLoadingLogin, onSetShouldShowResetPwForm, shouldShowResetPwForm } = this.props;
    const { getFieldDecorator } = form;
    const { passwordStrength } = this.state;

    const formItemLayout = {
      wrapperCol: {
        xxl: { span: 18, offset: 3 },
        xl: { span: 18, offset: 3 },
        lg: { span: 18, offset: 3 },
        md: { span: 18, offset: 3 },
        sm: { span: 18, offset: 3 },
        xs: { span: 18, offset: 3 },
      },
    };

    const layoutProps = {
      xxl: { span: 18, offset: 3 },
      xl: { span: 18, offset: 3 },
      lg: { span: 18, offset: 3 },
      md: { span: 18, offset: 3 },
      sm: { span: 18, offset: 3 },
      xs: { span: 18, offset: 3 },
    };

    return (
      <div>
        <fieldset style={shouldShowResetPwForm ? { display: 'none' } : {}}>
          <FormItem className="styled-form" {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: <InputError message="Email is required." />,
                },
              ],
            })(
              <Input
                className="styled-input"
                prefix={<Icon type="mail" />}
                type="email"
                placeholder="E-mail"
                size="large"
                inputMode="email"
              />,
            )}
          </FormItem>
          <FormItem className="styled-form" {...formItemLayout}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: <InputError message="Password is required." />,
                },
              ],
            })(
              <Input.Password
                className="styled-input"
                prefix={<Icon type="lock" />}
                placeholder="Password"
                size="large"
              />,
            )}
          </FormItem>
        </fieldset>
        {shouldShowResetPwForm ? (
          <fieldset>
            <p className="text-white">
              You have been required by your administrator to reset your password. Enter a new
              password below.
            </p>
            <FormItem className="styled-form" {...formItemLayout} style={{ marginBottom: '4px' }}>
              {getFieldDecorator('newPassword', {
                rules: [
                  { required: true, message: <InputError message="New password is required." /> },
                  {
                    validator: (rule, value, callback) => {
                      if (value && value === form.getFieldValue('password')) {
                        callback('New password must be different.');
                      } else {
                        callback();
                      }
                    },
                    message: <InputError message="New password must be different." />,
                  },
                  {
                    validator: (rule, value, callback) => {
                      const result = estimatePasswordStrength(value);
                      this.setState({ passwordStrength: result.score });
                      if (result.password && result.score < 3) {
                        callback('New password must be sufficiently strong.');
                      } else {
                        callback();
                      }
                    },
                    message: <InputError message="New password must be sufficiently strong." />,
                  },
                ],
              })(
                <Input.Password
                  className="styled-input"
                  prefix={<Icon type="lock" />}
                  placeholder="New password"
                  size="large"
                />,
              )}
              <PasswordStrengthIndicator level={passwordStrength} />
            </FormItem>
            <FormItem className="styled-form" {...formItemLayout}>
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: <InputError message="Please confirm your password." />,
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value && value !== form.getFieldValue('newPassword')) {
                        callback('Passwords do not match.');
                      } else {
                        callback();
                      }
                    },
                    message: <InputError message="Passwords do not match." />,
                  },
                ],
              })(
                <Input.Password
                  className="styled-input"
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder="Confirm password"
                  size="large"
                />,
              )}
            </FormItem>
          </fieldset>
        ) : null}
        <Col {...layoutProps}>
          {this.displayErrorMessage()}
          {this.displaySuccessMessage()}
        </Col>
        {shouldShowResetPwForm ? (
          <DefaultButton
            type="default"
            size="large"
            onClick={() => onSetShouldShowResetPwForm(false)}
            style={{ marginRight: '2em' }}
          >
            <Icon type="arrow-left" />
            Back
          </DefaultButton>
        ) : null}
        <StyledButton type="primary" htmlType="submit" block size="large" disabled={isLoadingLogin}>
          SIGN IN
        </StyledButton>
        {this.renderPccSection()}
      </div>
    );
  }

  renderPccSection() {
    const { pccIsLoadingLogin } = this.props;
    if (!pccEnabled) return null;
    return (
      <div>
        <Spacing size={20} />
        <Divider className="text-white">OR</Divider>
        <PccSignInButton onClick={this.handlePccSso} disabled={pccIsLoadingLogin} />
        {this.displayPccErrorMessage()}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSignupLink() {
    if (d2cEnabled) {
      return (
        <>
          <Spacing size={20} />
          <p className="text-white">
            New user?
            <Link href="/account/signup" as="/account/signup">
              <Button type="link" className="signup-link">
                Create an Account
              </Button>
            </Link>
          </p>
        </>
      );
    }
    return null;
  }

  render() {
    const { title, pccIsLoadingLogin, code } = this.props;

    let content;
    if (pccIsLoadingLogin || (code && !process.browser)) {
      content = <Spin />;
    } else {
      content = this.renderFormContent();
    }

    return (
      <Wrapper>
        <Form onSubmit={this.handleSubmit}>
          <div className="logo-wrapper">
            <Link href="/account/login" as="/account/login">
              <img
                src="https://5startelemed.nyc3.digitaloceanspaces.com/production/static/logo.png"
                alt="logo"
              />
            </Link>
          </div>
          <Spacing size={10} />
          <h1 className="title">{title}</h1>
          <Spacing size={10} />
          {content}
          <Spacing size={20} />
          {this.renderSignupLink()}
          <p className="text-white">
            By continuing,
            <br />
            you agree to our &nbsp;
            <a
              href="/terms"
              rel="noopener noreferrer"
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Terms of Use and Privacy Policy.
            </a>
          </p>
          <Spacing size={20} />
        </Form>
      </Wrapper>
    );
  }
}

const WrappedLoginForm = Form.create({
  name: 'login',
  onFieldsChange(props, fields, allFields) {
    const { form, onEmailChanged = () => {}, onPasswordChanged = () => {} } = props;
    if (
      'newPassword' in fields &&
      'confirmPassword' in allFields &&
      allFields.confirmPassword.value !== ''
    ) {
      form.setFieldsValue({ confirmPassword: '' });
    }
    if ('email' in fields) {
      onEmailChanged(fields.email.value);
    }
    if ('password' in fields) {
      onPasswordChanged(fields.password.value);
    }
  },
})(LoginForm);

export default WrappedLoginForm;
