import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import getConfig from 'next/config';
import { Mutation } from 'react-apollo';

const CREATE_PCC_AUTH_URL = gql`
  mutation createPccAuthUrl($action: PccStateAction!) {
    createPccAuthUrl(action: $action)
  }
`;

const { pccEnabled } = getConfig().publicRuntimeConfig;

export default action => Comp => {
  return class WithCreatePccAuthUrlMutation extends PureComponent {
    render() {
      if (!pccEnabled) return <Comp {...this.props} />;
      return (
        <Mutation mutation={CREATE_PCC_AUTH_URL} variables={{ action }}>
          {(createPccAuthUrl, { data, loading, error }) => (
            <Comp
              {...this.props}
              onCreatePccAuthUrl={createPccAuthUrl}
              pccAuthUrl={data && data.createPccAuthUrl}
              loadingCreatePccAuthUrl={loading}
              errorCreatePccAuthUrl={error}
            />
          )}
        </Mutation>
      );
    }
  };
};
