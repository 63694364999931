import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Router from 'next/router';
import LoginForm from 'components/AuthPages/LoginForm';
import withLogin from 'graphql/hocs/profile/withLogin';
import withLoginWithPcc from 'graphql/hocs/profile/withLoginWithPcc';
import AuthPageContainer from 'components/AuthPages/AuthPageContainer';

/* eslint-disable react/prefer-stateless-function */
@withLogin
@withLoginWithPcc
class LoginPage extends Component {
  static propTypes = {
    authToken: PropTypes.string,
    isLoadingLogin: PropTypes.bool,
    loginError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onLogin: PropTypes.func.isRequired,
    pccIsLoadingLogin: PropTypes.bool,
    pccLoginError: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    pccOnLogin: PropTypes.func,
    pccAuthToken: PropTypes.string,
    code: PropTypes.string.isRequired,
  };

  static defaultProps = {
    authToken: '',
    isLoadingLogin: false,
    loginError: null,
    pccIsLoadingLogin: false,
    pccLoginError: null,
    pccOnLogin: null,
    pccAuthToken: '',
  };

  state = {
    errorMessage: '',
    shouldShowResetPwForm: false,
    email: '',
    password: '',
  };

  static async getInitialProps({ req }) {
    const { code = '' } = (req && req.query) || {};
    return { code };
  }

  componentDidMount() {
    this.checkLogin();
  }

  componentDidUpdate(prevProps) {
    const { isLoadingLogin: prevIsLoadingLogin } = prevProps;
    const { authToken, isLoadingLogin, loginError, pccAuthToken } = this.props;
    if (authToken || pccAuthToken) {
      Router.push('/account/login/success');
    }
    if (prevIsLoadingLogin && !isLoadingLogin && loginError) {
      if (
        loginError.graphQLErrors.some(err => err.extensions.exception.code === 'RESET_PASSWORD')
      ) {
        this.setState({ shouldShowResetPwForm: true, errorMessage: '' });
      } else {
        let errorMessage = 'Invalid credentials. Please try again.';
        if (loginError.message.includes('Too many requests')) {
          const retryTime = loginError.message.slice(62, loginError.message.length);
          errorMessage = `You have reached the maximum number of retries. Please try again ${moment().to(
            retryTime,
          )}.`;
        }
        this.setState({ errorMessage });
      }
    }
  }

  handleSetShouldShowResetPwForm = shouldShowResetPwForm => {
    this.setState({ shouldShowResetPwForm });
  };

  handleSubmit = values => {
    const { onLogin } = this.props;
    const { email, password, shouldShowResetPwForm } = this.state;
    const input = { ...values, ...(shouldShowResetPwForm ? { email, password } : {}) };
    onLogin({
      variables: { input },
    });
  };

  handleEmailChanged = email => {
    const { shouldShowResetPwForm } = this.state;
    if (!shouldShowResetPwForm) this.setState({ email });
  };

  handlePasswordChanged = password => {
    const { shouldShowResetPwForm } = this.state;
    if (!shouldShowResetPwForm) this.setState({ password });
  };

  checkLogin() {
    const { pccOnLogin } = this.props;
    if (pccOnLogin) {
      pccOnLogin();
    }
  }

  render() {
    const {
      authToken,
      isLoadingLogin,
      pccIsLoadingLogin,
      pccLoginError,
      pccAuthToken,
      code,
    } = this.props;

    const { errorMessage, shouldShowResetPwForm } = this.state;

    let pccErrorMessage = '';
    if (pccLoginError) {
      if (pccLoginError.message.includes('Facility not found')) {
        pccErrorMessage =
          'Your facility is not yet configured to log in with PointClickCare. Please log in with your assigned 5-Star Telemed email and password.';
      } else {
        pccErrorMessage = 'Invalid credentials. Please try again.';
      }
    }

    return (
      <AuthPageContainer>
        <Helmet title="Login | 5-Star Telemed" />
        <LoginForm
          errorMessage={errorMessage}
          pccErrorMessage={pccErrorMessage}
          onSubmit={this.handleSubmit}
          isLoadingLogin={
            isLoadingLogin || pccIsLoadingLogin || Boolean(authToken) || Boolean(pccAuthToken)
          }
          title="Secure Login"
          code={code}
          onSetShouldShowResetPwForm={this.handleSetShouldShowResetPwForm}
          shouldShowResetPwForm={shouldShowResetPwForm}
          onEmailChanged={this.handleEmailChanged}
          onPasswordChanged={this.handlePasswordChanged}
        />
      </AuthPageContainer>
    );
  }
}

export default LoginPage;
