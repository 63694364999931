/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';

class Link extends Component {
  static propTypes = {
    as: PropTypes.string,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    shouldOpenInNewWindow: PropTypes.bool,
  };

  static defaultProps = {
    as: '',
    href: '',
    onClick: () => {},
    shouldOpenInNewWindow: false,
  };

  handleClick = event => {
    const { onClick } = this.props;
    onClick(event);
  };

  handleKeyDown = event => {
    const { onClick } = this.props;
    if (event.key === 'Enter') {
      onClick(event);
    }
  };

  render() {
    const { as, children, href, shouldOpenInNewWindow } = this.props;

    // If link is an absolute link, or if link should open in new window,
    // then directly us an `a` tag, insted of the `NextLink` component
    if (shouldOpenInNewWindow) {
      return (
        <a
          href={href}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          tabIndex={0}
          target={shouldOpenInNewWindow ? '_blank' : ''}
        >
          {children}
        </a>
      );
    }

    return (
      <NextLink href={href} as={as} passHref>
        <a onClick={this.handleClick} onKeyDown={this.handleKeyDown} role="link" tabIndex={0}>
          {children}
        </a>
      </NextLink>
    );
  }
}

export default Link;
