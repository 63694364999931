import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 12px;

  .message {
    background-color: #f2d5e4;
    color: red;
    border: 1px solid #f2d5e4;
    padding: 4px;
    border-radius: 4px;
  }

  .message:before {
    bottom: 18px;
    left: 10px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #f2d5e4;
    border-width: 8px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class InputError extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
  };

  render() {
    const { message } = this.props;

    return (
      <Wrapper>
        <span className="message">{message}</span>
      </Wrapper>
    );
  }
}

export default InputError;
