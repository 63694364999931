import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Col, Row } from 'antd';
import Spacing from 'components/common/Spacing';

const Wrapper = styled.div`
  padding-top: 80px;
  min-height: 100vh;
  position: relative;

  :before {
    background: url('https://5startelemed.nyc3.digitaloceanspaces.com/production/static/auth-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 1;
  }
  .styled-card {
    background: url('https://5startelemed.nyc3.digitaloceanspaces.com/production/static/auth-form-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 21px;
    text-align: center;

    @media screen and (max-width: 650px) {
      border-top-right-radius: 21px;
    } /* For responsiveness */
  }
  .rectangle {
    background-color: #1e253b;
    height: 25px;
    margin: -24px -24px 0 -24px;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class AuthPageContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    const layoutProps = {
      xxl: {
        span: 6,
        offset: 9,
      },
      xl: {
        span: 8,
        offset: 8,
      },
      lg: {
        span: 10,
        offset: 7,
      },
      md: {
        span: 12,
        offset: 6,
      },
      sm: {
        span: 14,
        offset: 5,
      },
      xs: {
        span: 22,
        offset: 1,
      },
    };

    return (
      <Wrapper>
        <Row>
          <Col {...layoutProps}>
            <Spacing size={20} />
            <Card className="styled-card">
              <div className="rectangle" {...layoutProps} />
              <Spacing size={20} />
              {children}
            </Card>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default AuthPageContainer;
