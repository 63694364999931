import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { inject } from 'mobx-react';
import { Mutation } from 'react-apollo';
import CommonAccount from '../../fragments/account';

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      authToken
      myProfile {
        ...CommonAccount
      }
    }
  }
  ${CommonAccount}
`;

export default Comp => {
  /* eslint-disable react/prefer-stateless-function */
  @inject('authStore')
  class WithLoginMutation extends Component {
    static propTypes = {
      authStore: PropTypes.object.isRequired,
    };

    handleLoginComplete = data => {
      const { authStore } = this.props;
      const { setAuthToken, setLoginMethod, setProfile, setRole } = authStore;
      const {
        login: { authToken, myProfile },
      } = data;
      setRole(myProfile.role);
      setAuthToken(authToken);
      setProfile(myProfile);
      setLoginMethod('password');
    };

    render() {
      return (
        <Mutation mutation={LOGIN} onError={() => undefined} onCompleted={this.handleLoginComplete}>
          {(login, { data, loading, error }) => (
            <Comp
              {...this.props}
              onLogin={login}
              authToken={data ? data.login.authToken : ''}
              isLoadingLogin={loading}
              loginError={error}
            />
          )}
        </Mutation>
      );
    }
  }

  hoistNonReactStatics(WithLoginMutation, Comp);

  return WithLoginMutation;
};
