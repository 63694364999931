import React, { PureComponent } from 'react';
import styled from 'styled-components';

const IMG_BASE = '/images/pcc/base.svg';
const IMG_DISABLED = '/images/pcc/disabled.svg';
const IMG_HOVER = '/images/pcc/hover.svg';
const IMG_PRESSED = '/images/pcc/pressed.svg';

const Styled = styled.button`
  width: 244px;
  height: 50px;
  border: none;
  cursor: pointer;
  background: url(${IMG_BASE}) transparent;
  font-size: 1px;

  &:disabled {
    background-image: url(${IMG_DISABLED});
  }

  &:hover {
    background-image: url(${IMG_HOVER});
  }

  &:active {
    background-image: url(${IMG_PRESSED});
  }
`;

function preloadImage(img) {
  new Image().src = img;
}

export default class PccSignInButton extends PureComponent {
  componentDidMount() {
    if (!process.browser) return;
    [IMG_BASE, IMG_HOVER, IMG_PRESSED].forEach(preloadImage);
  }

  render() {
    return (
      <Styled type="button" {...this.props}>
        Sign in with PointClickCare
      </Styled>
    );
  }
}
